import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

//need to import style json this so it will get loaded by webpack
import '../../map/map-style-2019.json'
import dcMap from './Map.vue'

//////// vue 2 leaflet 
import { Icon }  from 'leaflet'
import 'leaflet/dist/leaflet.css'

/////// leaflet fullscreen
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

import MarkerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import MarkerIcon from 'leaflet/dist/images/marker-icon.png';
import MarkerShadow from 'leaflet/dist/images/marker-shadow.png';

Icon.Default.mergeOptions({
  iconRetinaUrl: MarkerIcon2x,
  iconUrl: MarkerIcon,
  shadowUrl: MarkerShadow
});
/////////

// import mapboxgl from 'mapbox-gl';
// import 'mapbox-gl/dist/mapbox-gl.css';
// window.mapboxgl = mapboxgl;
// import 'mapbox-gl-leaflet';

import maplibregl from 'maplibre-gl'
window.maplibregl = maplibregl;
import '@maplibre/maplibre-gl-leaflet'

export default angular.module( 'directives.map', [] )
  .directive('dcMap', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcMap', dcMap))
  }])
