"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PoliciesModel = /** @class */ (function () {
    function PoliciesModel($http, APPURL) {
        this.$http = $http;
        this.APPURL = APPURL;
        this.URLS = {
            FETCH: APPURL + '/api/v1/policies',
            UPDATE: APPURL + '/api/v1/policies',
            CREATE: APPURL + '/api/v1/policies'
        };
        this.getByID = this.getByID.bind(this);
        this.get = this.getAll.bind(this);
        this.getByName = this.getByID.bind(this);
        this.update = this.update.bind(this);
        this.insert = this.insert.bind(this);
        this.remove = this.remove.bind(this);
        this.cacheResults = this.cacheResults.bind(this);
    }
    PoliciesModel.prototype.extract = function (result) {
        return result.data;
    };
    PoliciesModel.prototype.cacheResults = function (result) {
        var policies = this.extract(result);
        return policies;
    };
    PoliciesModel.prototype.getByID = function (id) {
        return this.$http.get(this.URLS.FETCH + '/' + id).then(this.cacheResults);
    };
    PoliciesModel.prototype.getAll = function () {
        return this.$http.get(this.URLS.FETCH).then(this.cacheResults);
    };
    PoliciesModel.prototype.update = function (request) {
        var data = Object.assign({}, request);
        delete data.id;
        return this.$http.put(this.URLS.FETCH + '/' + request.id, data).then(this.cacheResults);
    };
    PoliciesModel.prototype.insert = function (request) {
        return this.$http.post(this.URLS.FETCH, request).then(this.cacheResults);
    };
    PoliciesModel.prototype.remove = function (user) {
        return this.$http.delete(this.URLS.FETCH + '/' + user.id).then(this.cacheResults);
    };
    return PoliciesModel;
}());
PoliciesModel.$inject = ['$http', 'APPURL'];
exports.default = PoliciesModel;
