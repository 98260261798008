import {isJson} from "../../../../common/helpers/json";
import CompleteStudentAccountModalTemplate from './complete-account-modal.html'
import CompleteStudentAccountModalInstanceController from './complete-account-modal.controller'

class AdminStudentsVerificationController {

    constructor($scope, $state, UsersModel, FlashService, filterFilter, DriveSessionTypesModel, $uibModal, $q, $window) {
        this.$scope = $scope;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.$q = $q;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.filterFilter = filterFilter;
        this.loadingTypes = false;
        this.minutes = [];
        this.loading = true;
        this.deletingUser = false;
        this.$window = $window;
        for( let i = 0; i < 60; i++ ){
            if( i % 5 === 0){
                this.minutes.push({'name' : i, 'value':i})
            }
        }
        this.hours = [];
        for (let i = 0; i < 60; i++){
            this.hours.push({'name': i, 'value': i})
        }
        this.formdata = {
            'student' : null,
            'accredit_data' : []
        }

        this.processStudents = this.processStudents.bind(this);
        DriveSessionTypesModel.get().then(response => {
            this.sessiontypes = response.sessionTypes.filter(type => type.status === 'Active');
            this.sessiontypes.forEach(type => {
                this.formdata.accredit_data.push({
                    'type' : type,
                    'hours_purchased' : 0,
                    'minutes_purchased' : 0
                })
            })
            this.accreditData = [].concat(this.formdata.accredit_data);

            // Show all of the possible drive session types
            this.defaultAccreditData = [].concat(this.formdata.accredit_data);
        })

        this.UsersModel.getStudents({'filter': 'verify', 'page_by' : 20}).then(response => {
            this.totalItems = response.students.total;
            this.students = response.students.data.filter(this.processStudents);
            this.loading = false;
        })
    }

    deleteUser(id){
        const confirmDelete = this.$window.confirm('Are you sure you want to delete this user? ALL THE ACCOUNTS DATA WILL BE LOST. YOU CANNOT UNDO THIS.');
        if (confirmDelete === true){
            this.deletingUser = true;
            this.UsersModel.deleteStudent({'id' : id}).then(response => {
                this.deletingUser = false;
                this.FlashService.setMessage({
                    'type' : 'success',
                    'message' : response.message
                })
                this.students = this.students.filter(user => {
                    return user.id !== id;
                });
            }).catch(Error => {
                this.deletingUser = false;
            })
        }
    }

    completeStudentAccount(student){
        let getStudent = this.UsersModel.getStudentByID(student.id);
        let getPickupLocations = this.UsersModel.getPickupLocations(student.id);
        this.$q.all([getStudent, getPickupLocations]).then(responses => {
            this.pickuplocations = responses[1].pickuplocations;

            responses[0].school.address = JSON.parse(responses[0].school.address);
            responses[0].pickuplocations = responses[1].pickuplocations;
            let modalInstance = this.$uibModal.open({
                animation: true,
                template: CompleteStudentAccountModalTemplate,
                controller: CompleteStudentAccountModalInstanceController,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    'student': responses[0]
                }
            });

            modalInstance.result.then( (data) => {

            }, function () {
                //$log.info('Modal dismissed at: ' + new Date());
            });
        });
    }

    pageChanged() {
        let params = {'page': this.currentPage, 'filter': 'verify', 'page_by': 20};
        if (this.studentName) {
            params.name = this.studentName;
        }
        this.UsersModel.getStudents(params).then(response => {
            this.totalItems = response.students.total;
            this.students = response.students.data.filter(this.processStudents);
        })

    }

    search() {
        this.UsersModel.getStudents({
            'name': this.studentName,
            'page': this.currentPage,
            'page_by' : 20,
            'filter': 'verify'
        }).then(response => {
            this.totalItems = response.students.total;
            this.students = response.students.data.filter(this.processStudents);
        })
    }

    verifyStudent() {

        if (this.submitting) {
            return;
        }

        this.submitting = true;
        let formdata = Object.assign({}, this.formdata);
        formdata.accredit_data = formdata.accredit_data.map(data => {
            data.time_purchased_in_minutes = (data.hours_purchased * 60) + data.minutes_purchased
            return data;
        })

        this.UsersModel.verifyStudent(formdata).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.students = this.students.filter(student => {
                return student.id !== formdata.student.id
            })
            this.resetAccreditDataForm();
            // Open modal with student info in it
            if (response.student.setup_complete === false) {
                this.completeStudentAccount(formdata.student);
            }

            this.formdata.student = null;
            this.submitting = false;
        }).catch(response => {
            this.submitting = false;
        })
    }

    resetAccreditDataForm(){
        this.formdata.accredit_data = Object.assign({}, this.defaultAccreditData).map(accredit_data => {
            accredit_data.hours_purchased = 0;
            accredit_data.minutes_purchased = 0;
            return accredit_data;
        });
    }

    processStudents(student) {
        student.usermeta.hours_purchased = "2";
        student.usermeta.minutes_purchased = "0";

        if (typeof student.default_school_location[0] !== 'undefined') {
            student.location = student.default_school_location[0].name;
            if (typeof student.default_sector[0] !== 'undefined') {
                student.zone = student.default_sector[0].name;
            }
        }

        if(student.orders.length === 1){
            student.orders[0].lineitems = student.orders[0].lineitems.map(lineitem => {
                lineitem.product_details = isJson(lineitem.product_details) ? JSON.parse(lineitem.product_details) : lineitem.product_details;
                return lineitem;
            })
        }

        return student;
    }

    selectStudent(student){
        this.loadingTypes = true;
        this.order = null;
        this.formdata.accredit_data = [].concat(this.defaultAccreditData);
        this.UsersModel.getPurchasedTime(student.id).then(response => {
            if( response.data.length > 0 ){
                this.formdata.accredit_data.concat(response.data.filter(data => {
                    return data.type.auto_verify_on_purchase === false
                }));
            }else{
                this.formdata.accredit_data = [].concat(this.defaultAccreditData).map(accredit_data => {
                    accredit_data.hours_purchased = 0;
                    accredit_data.minutes_purchased = 0;
                    return accredit_data;
                });
            }
            if (this.formdata.accredit_data.length === 0) {
                this.formdata.accredit_data = [].concat(this.accreditData).map(accredit_data => {
                    accredit_data.hours_purchased = 0;
                    accredit_data.minutes_purchased = 0;
                    return accredit_data;
                });
            }
            this.loadingTypes = false;
        })
        this.formdata.student = student;
    }

    selectOrder(order){
        this.order = order;
    }
}

AdminStudentsVerificationController.$inject = ['$scope', '$state', 'UsersModel', 'FlashService', 'filterFilter', 'DriveSessionTypesModel', '$uibModal', '$q', '$window'];
export default AdminStudentsVerificationController;
