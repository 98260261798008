import {States} from "../../../../../common/constants/states";
import { isJson } from "../../../../../common/helpers/json";

class AdminSchoolsZonesIndexController {

    constructor($state, SchoolsModel, ZonesModel, $stateParams, UserService, FlashService, $window, ZipCodeModel) {
        this.$state = $state;
        this.$window = $window;
        this.$stateParams = $stateParams;
        this.SchoolsModel = SchoolsModel;
        this.ZonesModel = ZonesModel;
        this.FlashService = FlashService;
        this.ZipCodeModel = ZipCodeModel;
        this.states = States;
        this.school = UserService.getCurrentUser().school;
        this.loading = true;
        this.busy = false;
        this.zones = [];
        this.zone = null;
        this.zipCity = '';
        this.selectedZipCode = null;
        this.zipCodeSearchResults = [];
        this.getZones();
        this.getStateFromLocationID = this.getStateFromLocationID.bind(this);
    }

    getZones() {
        this.ZonesModel.getZones().then(response => {
            this.zones = response.zones.map(zone => {
                return zone;
            });
            this.loading = false;
        });
    }

    updateZone(zone) {
        this.busy = true;
        this.ZonesModel.editFallbackLocation(zone).then(response => {
            this.FlashService.setMessage({
                'message' : response.message,
                'type' : 'success'
            })
            this.busy = false;
        }).catch(Error => {
            this.busy = false;
        })
    }

    searchZipCodes() {
        let query = {state: this.getStateFromLocationID()}
        if (this.isNumeric(this.zipCity)) {
            query.number = this.zipCity;
        } else {
            query.city = this.zipCity;
        }
        this.ZipCodeModel.getZipCodes(query).then(response => {
            this.zipCodeSearchResults = response.zipcodes.map(zipcode => {
                return zipcode;
            });
        });
    }

    isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    getStateFromLocationID()
    {
        const location = this.zone.location;

        const address = isJson(location.address) ? JSON.parse(location.address) : location.address;
        return address.state;
    }

    addZipCode(zipcode){
        this.busy = true;

        this.ZonesModel.addZipCode(this.zone.id, {'zipcode' : zipcode.number}).then(response => {
            this.FlashService.setMessage({'type': 'success', 'message': response.message});
            this.zones = this.zones.map(zone => {
                if (zone.id == this.zone.id) {
                    zone.zoneparts.push(response.sector);
                }
                return zone;
            });
            this.zone = null;
            this.busy = false;
        }).catch(Error => {
            this.busy = false;
        });
    }

    deleteSector(zone,sector) {
        
        let confirm = this.$window.confirm('Are you sure you want to delete this sector?');
        if (!confirm) {
            return false;
        }
        
        this.ZonesModel.deleteSector(zone.id, sector.id).then(response => {
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            });
            this.zones = this.zones.map(z => {
                if (z.id === zone.id) {
                    z.zoneparts = z.zoneparts.filter(s => {
                        return s.id !== sector.id;
                    });
                }
                return z;
            });
        }).catch(Error => {

        })
    }

    deleteZone(zone)
    {
        let confirm = this.$window.confirm('Are you sure you want to delete this zone?');
        if (!confirm) {
            return false;
        }

        this.submitting = true;
        this.ZonesModel.deleteZone(zone.id).then(response => {
            this.FlashService.setMessage({
                type: 'success',
                message: response.message
            });
            this.zones = this.zones.filter(z => {
                return z.id !== zone.id;
            });
            this.submitting = false;
        }).catch(Error => {
            this.submitting = false;
            console.log(Error);
        })
    }

}
AdminSchoolsZonesIndexController.$inject = ['$state', 'SchoolsModel', 'ZonesModel', '$stateParams', 'UserService', 'FlashService', '$window', 'ZipCodeModel'];
export default AdminSchoolsZonesIndexController;
